<template>
  <div>
    <header-slot>
      <template #actions>
        <!-- Button Request Equipment -->
        <b-button @click="openModalRegister()" variant="info" class="mr-1">
          REGISTER PRODUCT
        </b-button>
      </template>
    </header-slot>
    <!-- Dynamic Route -->
    <b-nav card-header pills class="m-0">
      <!-- Button Equipment -->
      <b-nav-item
        :to="{ name: 'lo-list-products' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Stock</b-nav-item
      >
      <!-- Button Equipment Request -->
      <b-nav-item
        :to="{ name: 'lo-list-inputs' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Inputs
      </b-nav-item>
      <!-- Button To Assigned -->
      <b-nav-item
        :to="{ name: 'lo-list-outputs' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Outputs</b-nav-item
      >
    </b-nav>
    <!-- Dynamic Route -->
    <!-- <b-card
      no-body
    >
      <grilla-products ref="refProductsInventory"></grilla-products>
    </b-card> -->
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="key"></router-view>
    </b-card>
    <register-product v-if="modalRegister" @close="closeModalRegister" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
// import grilla
import GrillaProduts from "@/views/logistic/views/products-inventory/components/ProductsGrillas.vue";

// modals
import RegisterProduct from "./modals/RegisterProduct.vue";

import Vue from "vue";
export default {
  components: {
    "grilla-products": GrillaProduts,
    "register-product": RegisterProduct,
  },
  data() {
    return {
      modalRequest: false,
      countRequest: 0,
      modalRegister: false,
      key: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    openModalRegister() {
      this.modalRegister = true;
    },
    closeModalRegister() {
      this.modalRegister = false;
      this.key++;
      // this.$refs.refProductsInventory.reloadGrilla();
    },
  },

  watch: {
    $route() {
      this.key++;
    },
  },
};
</script>